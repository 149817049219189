<script>
/***
 Name: task_tpl
 Code: sa0ChunLuyu
 Time: 2021/11/8 19:37
 Remark: 任务模板列表
 */
export default {
  data() {
    return {
      expanded_keys: [],
      form_list: []
    }
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo();
  },
  methods: {
    mountedDo() {
      this.getTaskTplList()
      this.getFormInfo()
    },
    formClick(e) {
      let id = e[0] ? e[0] : this.$route.params.id
      if (Number(id) !== Number(this.$route.params.id)) {
        this.$router.push({
          params: {
            id: id
          }
        })
      }
    },
    getFormInfo() {
      this.$sa0.post({
        url: this.$api('根据id获取委托单详情'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.template
            if (info.type === 1) {
              this.expanded_keys = ((info.template_ids).toString()).split(',').map((item) => {
                return Number(item)
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    toTpl() {
      this.$router.push('/weituodanguanli/index/0')
    },
    getTaskTplList() {
      this.$sa0.post({
        url: this.$api('获取委托单分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.form_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
  }
}
</script>
<template>
  <div>
    <div class="task_tpl_title_wrapper">
      <div class="ml-3">委托单模板列表</div>
    </div>
    <div class="task_tpl_tree_wrapper ml-3">
      <a-tree :tree-data="form_list" :expandedKeys.sync="expanded_keys"
              :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true"
              @select="formClick">
        <a-icon slot="icon" type="carry-out"/>
      </a-tree>
    </div>
    <div @click="toTpl()" class="task_tpl_bottom_wrapper">
      <div class="ml-3">前往委托单模板管理</div>
    </div>
  </div>
</template>
<style scoped>

.task_tpl_title_wrapper {
  height: 40px;
  line-height: 40px;
  background: #eeeeee;
}

.task_tpl_bottom_wrapper:hover {
  font-weight: bold;
}

.task_tpl_bottom_wrapper {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  height: 40px;
  left: 0;
  right: 0;
  line-height: 40px;
  background: #eeeeee;
}

.task_tpl_tree_wrapper {
  position: absolute;
  top: 40px;
  bottom: 40px;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding-bottom: 20px;
}
</style>
