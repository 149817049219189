<script>
/***
 Name: form_input
 Code: sa0ChunLuyu
 Time: 2021/11/8 20:39
 Remark: 任务模板表单
 */
import moment from "moment";
export default {
  data() {
    return {
      form_info: false,
      task_info_content: [],
      task_info_main_content: [
        {
          id: 0,
          type: 1,
          title: '项目名称',
          key: 'f_name',
          content: '请输入项目名称',
          value: '',
        }, {
          id: 0,
          type: 1,
          title: '委托单编号',
          key: 'f_number',
          content: '请输入委托单编号',
          value: '',
        }, {
          id: 0,
          type: 1,
          title: '委托单位名称',
          key: 'f_cname',
          content: '请输入委托单位名称',
          value: '',
        }, {
          id: 0,
          type: 1,
          title: '申请日期',
          key: 'f_date',
          content: 2,
          value: '',
        }, {
          id: 0,
          type: 1,
          title: '联系人',
          key: 'f_person',
          content: '请输入联系人',
          value: '',
        }, {
          id: 0,
          type: 1,
          title: '联系电话',
          key: 'f_phone',
          content: '请输入电话',
          value: '',
        }, {
          id: 0,
          type: 4,
          title: '任务加急',
          key: 'f_urgent',
          content: ['是', '否'],
          value: 0,
        }]
    }
  },
  mounted() {
    this.mountedDo();
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  methods: {
    mountedDo() {
      this.getFormInfo()
    },
    getFormInfo() {
      this.$sa0.post({
        url: this.$api('根据id获取委托单详情'),
        data: {
          id: this.$route.params.id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.template
            if (info.type === 1) {
              this.form_info = info
              this.task_info_main_content[0].value = info.name
              this.task_info_main_content[3].value = moment().format('YYYY-MM-DD HH:mm:ss')
              this.task_info_content = response.data.template_content.map((item) => {
                let content = item.form_word.content
                let value = ''
                switch (item.form_word.type) {
                  case 0:
                  case 1:
                  case 5:
                  case 6:
                  case 7:
                    content = item.form_word.content;
                    break;
                  case 2:
                  case 3:
                  case 4:
                    content = JSON.parse(item.form_word.content);
                    break;
                }
                switch (item.form_word.type) {
                  case 0:
                  case 1:
                  case 5:
                    value = ''
                    break;
                  case 2:
                    value = content[0]
                    break;
                  case 4:
                    value = content[0]
                    break;
                  case 3:
                    value = []
                    break;
                  case 7:
                    switch (Number(content)) {
                      case 0:
                        value = moment().format('YYYY-MM-DD')
                        break;
                      case 1:
                        value = moment().format('HH:mm:ss')
                        break;
                      case 2:
                        value = moment().format('YYYY-MM-DD HH:mm:ss')
                        break;
                    }
                    break;
                  case 6:
                    value = [];
                    for (let i = 0; i < content; i++) {
                      value.push('')
                    }
                    break;
                }
                return {
                  id: item.form_word.id,
                  type: item.form_word.type,
                  title: item.form_word.name,
                  key: '',
                  content: content,
                  value: value,
                }
              });
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    imageUpload(e, key, k) {
      let param = new FormData()
      param.append('file', e.target.files[0])
      this.$sa0.file({
        url: this.$api('上传图片'),
        data: param
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          error: () => {
            layer.msg('上传失败')
          },
          then: (res) => {
            layer.msg('上传成功')
            this.$set(this.task_info_content[key].value, k, res.data.url)
          }
        })
      })
    },
  }
}
</script>
<template>
  <div class="pp_wrapper">
    <div v-if="form_info" class="form_info_name_wrapper">{{ form_info.name }}</div>
    <div class="form_info_input_wrapper">
      <div class="form_main_input_wrapper">
        <table class="layui-table">
          <colgroup>
            <col width="120">
            <col>
            <col width="120">
            <col>
            <col width="120">
            <col>
          </colgroup>
          <tbody>
          <tr>
            <td class="table_title_bg">项目名称</td>
            <td colspan="5">
              <input type="text" class="w-full h-full" style="" :placeholder="task_info_main_content[0].content"
                     v-model="task_info_main_content[0].value">
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">委托单编号</td>
            <td><input :placeholder="task_info_main_content[1].content" v-model="task_info_main_content[1].value"
                       type="text" class="w-full h-full" style=""></td>
            <td class="table_title_bg">委托单位</td>
            <td colspan="3"><input :placeholder="task_info_main_content[2].content"
                                   v-model="task_info_main_content[2].value" type="text" class="w-full h-full" style="">
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">申请日期</td>
            <td>
              <a-date-picker v-model="task_info_main_content[3].value" show-time
                             format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
            </td>
            <td class="table_title_bg">联系人</td>
            <td><input type="text" :placeholder="task_info_main_content[4].content"
                       v-model="task_info_main_content[4].value" class="w-full h-full" style=""></td>
            <td class="table_title_bg">联系电话</td>
            <td><input type="text" :placeholder="task_info_main_content[5].content"
                       v-model="task_info_main_content[5].value" class="w-full h-full" style=""></td>
          </tr>
          <tr v-for="(item,key) in task_info_content" :key="key">
            <td class="table_title_bg">{{ item.title }}</td>
            <td colspan="5">
              <div class="h-full" v-if="item.type === 1">
                <input class="w-full h-full" :placeholder="item.content" v-model="item.value"></input>
              </div>
              <div class="input_select_wrapper" v-if="item.type === 2">
                <a-select class="h-full w-full" :default-value="item.content[0]" v-model="item.value">
                  <a-select-option :value="i" v-for="(i,k) in item.content">
                    {{ i }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 3">
                <a-checkbox-group v-model="item.value">
                  <a-checkbox v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-checkbox>
                </a-checkbox-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 4">
                <a-radio-group v-model="item.value">
                  <a-radio v-for="(i,k) in item.content" :value="i">
                    {{ i }}
                  </a-radio>
                </a-radio-group>
              </div>
              <div class="content_item_wrapper input_line_wrapper" v-if="item.type === 5">
                <a-textarea v-model="item.value" :placeholder="item.content"></a-textarea>
              </div>
              <div class="content_image_item_wrapper" v-if="item.type === 6">
                <div class="image_item_wrapper" v-for="(i,k) in Number(item.content)">
                  <input type="file" class="image_input_wrapper" @change="(e)=>{imageUpload(e,key,k)}">
                  <div class="image_image_wrapper" v-if="item.value[k] !== ''">
                    <img :src="item.value[k]"/>
                  </div>
                  <a-icon type="plus"/>
                </div>
              </div>
              <div class="content_image_item_wrapper input_line_wrapper" v-if="item.type === 7">
                <a-date-picker v-model="item.value" v-if="Number(item.content) === 0" format="YYYY-MM-DD"
                               valueFormat="YYYY-MM-DD"/>
                <a-time-picker v-model="item.value" v-if="Number(item.content) === 1" format="HH:mm:ss"
                               valueFormat="HH:mm:ss"/>
                <a-date-picker v-model="item.value" show-time v-if="Number(item.content) === 2"
                               format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss"/>
              </div>
            </td>
          </tr>
          <tr>
            <td class="table_title_bg">任务加急</td>
            <td colspan="5">
              <a-radio-group name="radioGroup" :default-value="0">
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="0">
                  否
                </a-radio>
              </a-radio-group>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style scoped>

.input_select_wrapper {
  width: 200px;
}

.table_title_bg {
  background: #eeeeee;
}

.layui-table td {
  padding: 0 10px;
  height: 40px;
  border-color: #bbbbbb;
}

.form_main_input_wrapper {
  width: 900px;
}

.form_info_input_wrapper {
  position: absolute;
  top: 30px;
  overflow-y: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
}

.pp_wrapper {
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
}

.form_info_name_wrapper {
  font-size: 24px;
  height: 30px;
  line-height: 30px;
}

.content_image_item_wrapper {
  width: 600px;
}

.image_item_wrapper {
  position: relative;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #5a5e6630;
  background: #5a5e6605;
}

.image_image_wrapper img {
  width: 100%;
  height: 100%;
}

.image_image_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.image_input_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  cursor: pointer;
}

.input_line_wrapper {
  width: 500px;
  display: inline-block;
}
</style>
