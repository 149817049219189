<script>
/***
 Name: index
 Code: sa0ChunLuyu
 Time: 2021/11/8 18:57
 Remark: 填写创建任务委托单
 */
import TaskTpl from './task_tpl/task_tpl.vue'
import FormInput from './form_input/form_input.vue'
import TablePart from './table_part/table_part.vue'

export default {
  components: {TaskTpl, FormInput, TablePart},
  data() {
    return {
      ppage_height: 0,
      task_tpl_id: 0,
      left_show: false,
      top_show: true,
      bottom_show: false,
    }
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    saveClick() {
      let form_data = this.$refs.form_input.task_info_content
      console.log(form_data)
    },
    getTaskTplInfo() {
      if (this.task_tpl_id === 0) {
        this.left_show = true
        return;
      }
      this.$sa0.post({
        url: this.$api('根据id获取委托单详情'),
        data: {
          id: this.task_tpl_id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.template
            if (info.type !== 1) {
              this.$router.push({
                params: {
                  id: 0
                }
              })
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo() {
      this.task_tpl_id = Number(this.$route.params.id) ? Number(this.$route.params.id) : 0;
      this.getTaskTplInfo()
      this.ppage_height = this.$refs.ppage_ref.offsetHeight
    },
    ppageLeftShowButtonClick() {
      this.left_show = !this.left_show
    },
    ppageTopShowButtonClick() {
      this.top_show = !this.top_show
      if (!this.top_show) this.bottom_show = true
    },
    ppageBottomShowButtonClick() {
      this.bottom_show = !this.bottom_show
      if (!this.bottom_show) this.top_show = true
    }
  }
}
</script>
<template>
  <div>
    <div ref="ppage_ref" class="ppage_wrapper">
      <div class="ppage_left_wrapper" v-show="left_show">
        <TaskTpl></TaskTpl>
      </div>
      <div class="ppage_right_wrapper" :style="{
        left:left_show?'250px':'0'
      }">
        <div class="ppage_left_show_button">
          <div @click="ppageLeftShowButtonClick()" class="ppage_show_button">
            <i :class="left_show?'czs-angle-left-l':'czs-angle-right-l'"></i>
          </div>
        </div>
        <div class="ppage_right_top_wrapper" v-show="top_show"
             :style="{
          height:bottom_show?((ppage_height/2) -10)+'px':(ppage_height - 20)+'px'
        }">
          <FormInput ref="form_input"></FormInput>
        </div>
        <div class="ppage_right_mid_wrapper">
          <div @click="ppageTopShowButtonClick()" class="ppage_right_mid_top_wrapper">
            <i :class="top_show?'czs-angle-up-l':'czs-angle-down-l'"></i>
          </div>
          <div class="pp_button_wrapper">
            <a-button @click="saveClick()" size="small" type="primary">保存</a-button>
          </div>
          <div @click="ppageBottomShowButtonClick()" class="ppage_right_mid_top_wrapper">
            <i :class="bottom_show?'czs-angle-down-l':'czs-angle-up-l'"></i>
          </div>
        </div>
        <div class="ppage_right_bottom_wrapper" v-show="bottom_show">
          <TablePart ref="table_part"></TablePart>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.ppage_right_bottom_wrapper{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.pp_button_wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.ppage_right_top_wrapper {

}

.ppage_right_mid_top_wrapper {
  width: 100%;
  text-align: center;
  background: #eeeeee;
  line-height: 10px;
  height: 10px;
}

.ppage_right_mid_wrapper {
  font-size: 12px;
  position: relative;
}

.ppage_show_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 3px;
  font-size: 12px;
  background: #eeeeee;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
}

.ppage_left_show_button {
  position: absolute;
  left: 0;
  width: 5px;
  top: 0;
  bottom: 0;
  background: #eeeeee;
  z-index: 99;
}

.ppage_right_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.ppage_left_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
}

.ppage_wrapper {
  position: fixed;
  top: 160px;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
