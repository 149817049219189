<script>
/***
 Name: table_part
 Code: sa0ChunLuyu
 Time: 2021/11/8 21:05
 Remark: 表格部分
 */
import AddYangPin from './add_yang_pin/add_yang_pin.vue'

export default {
  components: {AddYangPin},
  data() {
    return {
      add_link_id: 0,
      add_type: 1,
      add_key: [0, -1],
      ats_visible: false,
      specimen_table: [
          {
        "specimen_id": 21,
        "specimen_type": 1,
        "specimen_in_number": "TRQ-1000001",
        "specimen_out_number": "TRQ-1000001",
        "specimen_link_id": 0,
        "children": []
      }, {
        "specimen_id": 17,
        "specimen_type": 1,
        "specimen_in_number": "试试",
        "specimen_out_number": "5435",
        "specimen_link_id": 0,
        "children": [{
          "specimen_id": 0,
          "specimen_type": 3,
          "specimen_in_number": "",
          "specimen_out_number": "",
          "specimen_link_id": 17,
          "children": []
        }, {
          "specimen_id": 15,
          "specimen_type": 2,
          "specimen_in_number": "8933323",
          "specimen_out_number": "23",
          "specimen_link_id": 17,
          "children": [{
            "specimen_id": 0,
            "specimen_type": 3,
            "specimen_in_number": "",
            "specimen_out_number": "",
            "specimen_link_id": 15,
            "children": []
          }]
        }, {
          "specimen_id": 9,
          "specimen_type": 2,
          "specimen_in_number": "789",
          "specimen_out_number": "987",
          "specimen_link_id": 17,
          "children": []
        }]
      }, {
        "specimen_id": 8,
        "specimen_type": 1,
        "specimen_in_number": "110119",
        "specimen_out_number": "112110",
        "specimen_link_id": 0,
        "children": [{
          "specimen_id": 18,
          "specimen_type": 2,
          "specimen_in_number": "adv",
          "specimen_out_number": "8768",
          "specimen_link_id": 8,
          "children": []
        }, {
          "specimen_id": 21,
          "specimen_type": 2,
          "specimen_in_number": "TRQ-1000001",
          "specimen_out_number": "TRQ-1000001",
          "specimen_link_id": 8,
          "children": []
        }]
      }, {
        "specimen_id": 5,
        "specimen_type": 1,
        "specimen_in_number": "1111",
        "specimen_out_number": "3333",
        "specimen_link_id": 0,
        "children": [{
          "specimen_id": 0,
          "specimen_type": 3,
          "specimen_in_number": "",
          "specimen_out_number": "",
          "specimen_link_id": 5,
          "children": []
        }]
      }],
      specimen_list: [],
    }
  },
  mounted() {
    this.mountedDo()
    this.makeTableItemList();
  },
  methods: {
    makeTableItemList() {
      this.specimen_table.map((item, key) => {
        this.useSidGetIList(item.specimen_id, 1, [key])
        this.specimen_table[key].children.map((i, k) => {
          if (i.specimen_type === 2) {
            this.useSidGetIList(i.specimen_id, 2, [key, k])
          } else {
            this.useSidGetIList(i.specimen_link_id, 2, [key, k])
          }
          this.specimen_table[key].children[k].children.map((ii, kk) => {
            if (i.specimen_type === 2) {
              this.useSidGetIList(ii.specimen_id, 3, [key, k, kk])
            } else {
              this.useSidGetIList(ii.specimen_link_id, 3, [key, , kk])
            }
          })
        })
      })
    },
    detItemChange(det_item_id, type, key) {
      this.$sa0.post({
        url: this.$api('根据id获取检测项目详情'),
        data: {
          id: det_item_id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let info = response.data.det_item
            switch (type) {
              case 1:
                this.$set(this.specimen_table[key[0]], 'item_info', info)
                this.$set(this.specimen_table[key[0]], 'factor_ids', [])
                break;
              case 2:
                this.$set(this.specimen_table[key[0]].children[key[1]], 'item_info', info)
                this.$set(this.specimen_table[key[0]].children[key[1]], 'factor_ids', [])
                break;
              case 3:
                this.$set(this.specimen_table[key[0]].children[key[1]].children[key[2]], 'item_info', info)
                this.$set(this.specimen_table[key[0]].children[key[1]].children[key[2]], 'factor_ids', [])
                break;
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    specimenChange(e, type, key) {
      let info = '';
      this.specimen_list.map((item) => {
        if (Number(item.id) === Number(e)) {
          info = item
        }
      })
      switch (type) {
        case 1:
          this.$set(this.specimen_table[key[0]], 'specimen_in_number', info.inter_code)
          this.$set(this.specimen_table[key[0]], 'specimen_out_number', info.source_code)
          this.useSidGetIList(e, 1, key)
          this.specimen_table[key[0]].children.map((item, k) => {
            this.$set(this.specimen_table[key[0]].children[k], 'specimen_link_id', e)
            if (item.specimen_type === 3) this.useSidGetIList(e, 2, [...key, k])
          })
          break;
        case 2:
          this.$set(this.specimen_table[key[0]].children[key[1]], 'specimen_in_number', info.inter_code)
          this.$set(this.specimen_table[key[0]].children[key[1]], 'specimen_out_number', info.source_code)
          this.useSidGetIList(e, 2, key)
          this.specimen_table[key[0]].children[key[1]].children.map((item, k) => {
            this.$set(this.specimen_table[key[0]].children[key[1]].children[k], 'specimen_link_id', e)
            if (item.specimen_type === 3) this.useSidGetIList(e, 3, [...key, k])
          })
          break;
      }
    },
    useSidGetIList(id, type, key) {
      this.$sa0.post({
        url: this.$api('临时检测项目列表'),
        data: {
          sample_id: id
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            let list = response.data.list
            let id = list[0] ? list[0].id : 0
            switch (type) {
              case 1:
                this.$set(this.specimen_table[key[0]], 'item_list', list)
                this.$set(this.specimen_table[key[0]], 'item_id', id)
                break;
              case 2:
                this.$set(this.specimen_table[key[0]].children[key[1]], 'item_list', list)
                this.$set(this.specimen_table[key[0]].children[key[1]], 'item_id', id)
                break;
              case 3:
                this.$set(this.specimen_table[key[0]].children[key[1]].children[key[2]], 'item_list', list)
                this.$set(this.specimen_table[key[0]].children[key[1]].children[key[2]], 'item_id', id)

                break;
            }
            if (id) this.detItemChange(id, type, key)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    mountedDo() {
      this.getSpecimenList();
    },
    getSpecimenList() {
      this.$sa0.post({
        url: this.$api('获取样品列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.specimen_list = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    AstCallback(item) {
      if (this.add_key[0] === -1) {
        this.specimen_table.unshift(item)
      } else {
        if (this.add_key[1] === -1) {
          this.specimen_table[this.add_key[0]].children.unshift(item)
        } else {
          this.specimen_table[this.add_key[0]].children[this.add_key[1]].children.unshift(item)
        }
      }
      console.log(JSON.stringify(this.specimen_table))
      this.closeATS();
    },
    delThis(type, key) {
      switch (type) {
        case 1:
          this.specimen_table.splice(key[0], 1)
          break;
        case 2:
          this.specimen_table[key[0]].children.splice(key[1], 1)
          break;
        case 3:
          this.specimen_table[key[0]].children[key[1]].children.splice(key[2], 1)
          break;
      }
    },
    inNumberChange(e, id) {
      let code = e.target.value
      this.specimen_table.map((item, key) => {
        if (item.specimen_id === id) this.$set(this.specimen_table[key], 'specimen_in_number', code)
        this.specimen_table[key].children.map((i, k) => {
          if (i.specimen_id === id) this.$set(this.specimen_table[key].children[k], 'specimen_in_number', code)
          this.specimen_table[key].children[k].children.map((ii, kk) => {
            if (ii.specimen_id === id) this.$set(this.specimen_table[key].children[k].children[kk], 'specimen_in_number', code)
          })
        })
      })
    },
    outNumberChange(e, id) {
      let code = e.target.value
      this.specimen_table.map((item, key) => {
        if (item.specimen_id === id) this.$set(this.specimen_table[key], 'specimen_out_number', code)
        this.specimen_table[key].children.map((i, k) => {
          if (i.specimen_id === id) this.$set(this.specimen_table[key].children[k], 'specimen_out_number', code)
          this.specimen_table[key].children[k].children.map((ii, kk) => {
            if (ii.specimen_id === id) this.$set(this.specimen_table[key].children[k].children[kk], 'specimen_out_number', code)
          })
        })
      })
    },
    openATS() {
      this.ats_visible = true
    },
    closeATS() {
      this.ats_visible = false
    },
    linkInfo(id) {
      let info = '';
      this.specimen_list.map((item) => {
        if (Number(item.id) === Number(id)) {
          info = item.name
        }
      })
      return info
    },
    addYangPin(info) {
      this.add_type = info.add_type
      this.add_key = info.add_key
      this.add_link_id = info.add_link_id
      this.openATS();
    },
    rowDeep(item) {
      if (item.factor_list && item.item_info && item.item_info.factor_type === 2) {
        return item.factor_list.length
      } else {
        return 0
      }
    },
    removeFactor(type, keys, key) {
      let ids = []
      switch (type) {
        case 1:
          ids = this.specimen_table[keys[0]].factor_ids
          ids.splice(key, 1)
          this.$set(this.specimen_table[keys[0]], 'factor_ids', ids)
          this.factorChange(ids, type, keys, this.specimen_table[keys[0]].factor_list)
          break;
        case 2:
          ids = this.specimen_table[keys[0]].children[keys[1]].factor_ids
          console.log('idsidsidsidsidsids1', ids)
          ids.splice(key, 1)
          console.log('idsidsidsidsidsids2', ids)
          this.$set(this.specimen_table[keys[0]].children[keys[1]], 'factor_ids', ids)
          this.factorChange(ids, type, keys, this.specimen_table[keys[0]].children[keys[1]].factor_list)
          break;
        case 3:
          ids = this.specimen_table[keys[0]].children[keys[1]].children[keys[2]].factor_ids
          ids.splice(key, 1)
          this.$set(this.specimen_table[keys[0]].children[keys[1]].children[keys[2]], 'factor_ids', ids)
          this.factorChange(ids, type, keys, this.specimen_table[keys[0]].children[keys[1]].children[keys[2]].factor_list)
          break;
      }
    },
    factorChange(e, type, key, list) {
      let fl = [];
      e.map((item) => {
        list.map((i) => {
          if (Number(i.id) === Number(item)) {
            fl.push(i)
          }
        })
      })
      switch (type) {
        case 1:
          this.$set(this.specimen_table[key[0]], 'factor_list', fl)
          break;
        case 2:
          this.$set(this.specimen_table[key[0]].children[key[1]], 'factor_list', fl)
          break;
        case 3:
          this.$set(this.specimen_table[key[0]].children[key[1]].children[key[2]], 'factor_list', fl)
          break;
      }
    },
  }
}
</script>
<template>
  <div class="pp_wrapper">
    <AddYangPin v-if="ats_visible"
                :add_type="add_type"
                :add_link_id="add_link_id"
                :add_key="add_key"
                :table="specimen_table"
                :callback="AstCallback"
                :close="closeATS"></AddYangPin>
    <div class="mt-2">
      <button @click="addYangPin({
      add_type:1,
      add_key:[-1, -1],
      add_link_id:0,
      })" class="layui-btn layui-btn-normal mt-5">
        添加普通样品
      </button>
    </div>
    <div class="table_wrapper">
      <table class="layui-table">
        <colgroup>
          <col width="120">
          <col width="200">
          <col width="200">
          <col width="200">
          <col width="200">
          <col>
        </colgroup>
        <thead>
        <tr>
          <th>样品类型</th>
          <th>样品名称</th>
          <th>样品编号</th>
          <th>外部编号</th>
          <th>关联样品</th>
          <th colspan="2">检测项目</th>
          <th>检测方法</th>
          <th>操作</th>
        </tr>
        </thead>
        <template v-for="(item,key) in specimen_table">
          <tr>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1">
              <!--              样品类型-->
              <div class="text-blue-400"><b>普通样品</b></div>
            </td>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1" :colspan="item.specimen_type !== 3 ? 1 : 3">
              <!--              样品名称-->
              <a-select v-if="item.specimen_type !== 3" v-model="item.specimen_id" class="w-full form_input_wrapper"
                        @change="(e)=>{specimenChange(e,1,[key])}">
                <a-select-option v-for="(i,k) in specimen_list" :key="key" :value="i.id">
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </td>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1" v-if="item.specimen_type !== 3">
              <!--              样品编号-->
              <a-input @change="(e)=>{inNumberChange(e,item.specimen_id)}" v-model="item.specimen_in_number"></a-input>
            </td>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1" v-if="item.specimen_type !== 3">
              <!--              外部编号-->
              <a-input @change="(e)=>{outNumberChange(e,item.specimen_id)}" class="w-full"
                       v-model="item.specimen_out_number"></a-input>
            </td>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1">
              <!--              关联样品-->
            </td>
            <td :colspan="item.item_info&&item.item_info.factor_type === 2?1:2">
              <!--              检测项目-->
              <a-select v-model="item.item_id" @change="(e)=>{detItemChange(e,1,[key])}"
                        class="w-full form_input_wrapper">
                <a-select-option v-for="(i,k) in item.item_list" :key="k" :value="i.id">
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </td>
            <td v-if="item.item_info&&item.item_info.factor_type === 2">
              <!--              选择多因子-->
              <a-select
                  mode="multiple"
                  placeholder="请选择多因子"
                  class="w-full"
                  v-model="item.factor_ids"
                  @change="(e)=>{factorChange(e,1,[key],item.item_info.factor)}"
              >
                <a-select-option v-for="(i,k) in item.item_info.factor" :value="i.id">
                  {{ i.name }}
                </a-select-option>
              </a-select>
            </td>
            <td :rowspan="rowDeep(item)?rowDeep(item) + 1:1">
<!--              检测方法-->
            </td>
            <td>
              <!--              操作-->
              <a-space>
                <button @click="delThis(1,[key])" class="layui-btn layui-btn-danger layui-btn-sm">移除</button>
                <button @click="addYangPin({
      add_type:2,
      add_key:[key, -1],
      add_link_id:item.specimen_id,
      })" class="layui-btn layui-btn-sm">添加平行样品
                </button>
                <button @click="addYangPin({
      add_type:3,
      add_key:[key, -1],
      add_link_id:item.specimen_id,
      })" class="layui-btn layui-btn-warm layui-btn-sm">添加空白样品
                </button>
              </a-space>
            </td>
          </tr>
          <template v-if="rowDeep(item)">
            <tr v-for="(fi,fk) in item.factor_list">
              <!--              多因子列表-->
              <td colspan="2">
                <!--              多因子-->
                <a-space>
                  <i @click="removeFactor(1,[key],fk)" class="czs-close-l"></i>
                  <div>{{ fi.name }}</div>
                </a-space>
              </td>
              <td></td>
            </tr>
          </template>
          <template v-for="(iitem,kkey) in item.children">
            <tr>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1">
                <!--                样品类型-->
                <div v-if="iitem.specimen_type === 2" class="text-green-400"><b>平行样品</b></div>
                <div v-if="iitem.specimen_type === 3" class="text-yellow-400"><b>空白样品</b></div>
              </td>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1" :colspan="iitem.specimen_type !== 3 ? 1 : 3">
                <!--                样品名称-->
                <a-select v-if="iitem.specimen_type !== 3" v-model="iitem.specimen_id" class="w-full form_input_wrapper"
                          @change="(e)=>{specimenChange(e,2,[key,kkey])}">
                  <a-select-option v-for="(i,k) in specimen_list" :key="k" :value="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </td>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1" v-if="iitem.specimen_type !== 3">
                <!--                样品编号-->
                <a-input @change="(e)=>{inNumberChange(e,iitem.specimen_id)}"
                         v-model="iitem.specimen_in_number"></a-input>
              </td>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1" v-if="iitem.specimen_type !== 3">
                <!--                外部编号-->
                <a-input @change="(e)=>{outNumberChange(e,iitem.specimen_id)}" class="w-full"
                         v-model="iitem.specimen_out_number"></a-input>
              </td>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1">
                <!--                关联样品-->
                <div :class="[
                    item.specimen_type === 2?'text-green-400':'text-blue-400'
                ]"><b>{{ linkInfo(iitem.specimen_link_id) }} {{ item.specimen_in_number }}</b></div>
              </td>
              <td :colspan="iitem.item_info&&iitem.item_info.factor_type === 2?1:2">
                <!--                检测项目-->
                <a-select v-model="iitem.item_id" @change="(e)=>{detItemChange(e,2,[key,kkey])}"
                          class="w-full form_input_wrapper">
                  <a-select-option v-for="(i,k) in iitem.item_list" :key="k" :value="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </td>
              <td v-if="iitem.item_info&&iitem.item_info.factor_type === 2">
                <!--                选择多因子-->
                <a-select
                    mode="multiple"
                    placeholder="请选择多因子"
                    class="w-full"
                    v-model="iitem.factor_ids"
                    @change="(e)=>{factorChange(e,2,[key,kkey],iitem.item_info.factor)}"
                >
                  <a-select-option v-for="(i,k) in iitem.item_info.factor" :value="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </td>
              <td :rowspan="rowDeep(iitem)?rowDeep(iitem) + 1:1">
                <!--              检测方法-->
              </td>
              <td>
                <!--                操作-->
                <a-space>
                  <button @click="delThis(2,[key,kkey])" class="layui-btn layui-btn-danger layui-btn-sm">移除</button>
                  <button v-if="iitem.specimen_type === 2" @click="addYangPin({
      add_type:3,
      add_key:[key, kkey],
      add_link_id:iitem.specimen_id,
      })" class="layui-btn layui-btn-warm layui-btn-sm">添加空白样品
                  </button>
                </a-space>
              </td>
            </tr>
            <template v-if="rowDeep(iitem)">
              <tr v-for="(fi,fk) in iitem.factor_list">
                <!--              多因子列表-->
                <td colspan="2">
                  <!--              多因子-->
                  <a-space>
                    <i @click="removeFactor(2,[key,kkey],fk)" class="czs-close-l"></i>
                    <div>{{ fi.name }}</div>
                  </a-space>
                </td>
                <td></td>
              </tr>
            </template>
            <template v-for="(iiitem,kkkey) in iitem.children">
              <tr>
                <td :rowspan="rowDeep(iiitem)?rowDeep(iiitem) + 1:1">
                  <!--                样品类型-->
                  <div class="text-yellow-400"><b>空白样品</b></div>
                </td>
                <td :rowspan="rowDeep(iiitem)?rowDeep(iiitem) + 1:1" colspan="3">
                  <!--                样品名称-->
                  <!--                样品编号-->
                  <!--                外部编号-->
                </td>
                <td :rowspan="rowDeep(iiitem)?rowDeep(iiitem) + 1:1">
                  <!--                关联样品-->
                  <div class="text-green-400"><b>{{ linkInfo(iiitem.specimen_link_id) }} {{
                      iitem.specimen_in_number
                    }}</b></div>
                </td>
                <td :colspan="iiitem.item_info&&iiitem.item_info.factor_type === 2?1:2">
                  <!--                检测项目-->
                  <a-select v-model="iiitem.item_id" @change="(e)=>{detItemChange(e,3,[key,kkey,kkkey])}"
                            class="w-full form_input_wrapper">
                    <a-select-option v-for="(i,k) in iiitem.item_list" :key="k" :value="i.id">
                      {{ i.name }}
                    </a-select-option>
                  </a-select>
                </td>
                <td v-if="iiitem.item_info&&iiitem.item_info.factor_type === 2">
                  <!--                选择多因子-->
                  <a-select
                      mode="multiple"
                      placeholder="请选择多因子"
                      class="w-full"
                      v-model="iiitem.factor_ids"
                      @change="(e)=>{factorChange(e,3,[key,kkey,kkkey],iiitem.item_info.factor)}"
                  >
                    <a-select-option v-for="(i,k) in iiitem.item_info.factor" :value="i.id">
                      {{ i.name }}
                    </a-select-option>
                  </a-select>
                </td>
                <td :rowspan="rowDeep(iiitem)?rowDeep(iiitem) + 1:1">
                  <!--              检测方法-->
                </td>
                <td>
                  <!--                操作-->
                  <a-space>
                    <button @click="delThis(3,[key,kkey,kkkey])" class="layui-btn layui-btn-danger layui-btn-sm">移除
                    </button>
                  </a-space>
                </td>
              </tr>
              <template v-if="rowDeep(iiitem)">
                <tr v-for="(fi,fk) in iiitem.factor_list">
                  <!--              多因子列表-->
                  <td colspan="2">
                    <!--              多因子-->
                    <a-space>
                      <i @click="removeFactor(3,[key,kkey,kkkey],fk)" class="czs-close-l"></i>
                      <div>{{ fi.name }}</div>
                    </a-space>
                  </td>
                  <td></td>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>
<style scoped>
.table_wrapper {
  position: absolute;
  top: 60px;
  bottom: 15px;
  left: 10px;
  right: 10px;
  overflow-y: auto;
}

.pp_wrapper {
  position: relative;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
}
</style>
