<script>
/***
 Name: add_task_specimen
 Code: sa0ChunLuyu
 Time: 2021/11/8 09:27
 Remark: 添加表格中的样品
 */
export default {
  props: ['close', 'callback', 'table', 'add_link_id', 'add_type', 'add_key'],
  data() {
    return {
      visible: true,
      specimen_list: [],
      specimen_id: 0,
      specimen_info: false,
      specimen_type: 1,
      specimen_in_number: '',
      specimen_out_number: '',
      specimen_link_id: 0,
    }
  },
  mounted() {
    this.getSpecimenList()
    this.specimen_type = this.add_type
    this.specimen_link_id = this.add_link_id
  },
  methods: {
    addThis() {
      let data = {
        specimen_id: this.specimen_type === 3 ? 0 : this.specimen_id,
        specimen_type: this.specimen_type,
        specimen_in_number: this.specimen_type === 3 ? '' : this.specimen_in_number,
        specimen_out_number: this.specimen_type === 3 ? '' : this.specimen_out_number,
        specimen_link_id: this.specimen_type === 1 ? 0 : this.specimen_link_id,
        children: []
      }
      if ((data.specimen_type === 3 || data.specimen_type === 2) && data.specimen_link_id === 0) {
        return layer.msg('请选择关联样品')
      }
      if ((data.specimen_type === 1 || data.specimen_type === 2) && data.specimen_id === 0) {
        return layer.msg('请选择样品')
      }
      this.callback(data)
    },
    onClose() {
      this.close()
    },
    getSpecimenList() {
      this.$sa0.post({
        url: this.$api('获取样品列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.specimen_list = response.data.list
            this.specimen_id = this.specimen_list[0].id
            this.specimenChange(this.specimen_id)
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    specimenChange(e) {
      let info = '';
      this.specimen_list.map((item) => {
        if (Number(item.id) === Number(e)) {
          info = item
        }
      })
      this.specimen_in_number = info.inter_code
      this.specimen_info = info
      this.specimen_out_number = info.source_code
    },
  }
}
</script>
<template>
  <div>
    <a-drawer
        width="500px"
        title="添加检测样品"
        placement="right"
        :visible="visible"
        @close="onClose"
    >
      <div>
        <div class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"><b>样品类型</b></div>
            <div>
              <a-select disabled v-model="specimen_type" class="form_input_wrapper">
                <a-select-option :value="1">普通样品</a-select-option>
                <a-select-option :value="2">平行样品</a-select-option>
                <a-select-option :value="3">空白样品</a-select-option>
              </a-select>
            </div>
          </a-space>
        </div>
        <div v-if="Number(specimen_type) !== 3" class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"><b>样品</b></div>
            <div>
              <a-select v-model="specimen_id" class="form_input_wrapper" @change="specimenChange">
                <a-select-option v-for="(item,key) in specimen_list" :key="key" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-space>
        </div>
        <div v-if="Number(specimen_type) !== 3" class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"><b>内部编号</b></div>
            <div>
              <a-input class="form_input_wrapper" v-model="specimen_in_number"></a-input>
            </div>
          </a-space>
        </div>
        <div v-if="Number(specimen_type) !== 3" class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"><b>外部编号</b></div>
            <div>
              <a-input class="form_input_wrapper" v-model="specimen_out_number"></a-input>
            </div>
          </a-space>
        </div>
        <div v-if="Number(specimen_type) !== 1" class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"><b>关联样品</b></div>
            <div>
              <div>
                <a-select disabled v-model="specimen_link_id" class="form_input_wrapper">
                  <a-select-option v-for="(item,key) in specimen_list" :key="key" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </a-space>
        </div>
        <div class="form_line_wrapper">
          <a-space>
            <div class="form_title_wrapper"></div>
            <div>
              <a-button type="primary" @click="addThis()">添加</a-button>
            </div>
          </a-space>
        </div>
      </div>
    </a-drawer>

  </div>
</template>
<style scoped>
.form_line_wrapper {
  margin: 10px 0;
}

.form_input_wrapper {
  width: 300px;
}

.form_title_wrapper {
  width: 80px;
  text-align: right;
}
</style>
